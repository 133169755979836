/* start vars: m-unit-converter */
/* line 13, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
.m-unit-converter {
	position: relative;
	vertical-align: middle;
}

/* line 17, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
.m-unit-converter--inline {
	border-right: 1px solid rgb(var(--color-global-border));
	padding-right: 10px;
	display: inline-block;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-unit-converter--inline {
	border-right: 0;
	border-left: 1px solid rgb(var(--color-global-border));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-unit-converter--inline {
	padding-right: initial;
	padding-left: 10px;
}

@media only screen and (max-width: 59.999em) {
	/* line 17, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
	.m-unit-converter--inline {
		border-right: 0;
		padding-right: 5px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .m-unit-converter--inline {
		border-right: 0;
		border-left: 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .m-unit-converter--inline {
		padding-right: initial;
		padding-left: 5px;
	}
}

/* line 29, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
.unit-converter__wrapper {
	position: relative;
}

/* line 33, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
.unit-converter__list {
	padding-left: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .unit-converter__list {
	padding-left: initial;
	padding-right: 5px;
}

@media only screen and (max-width: 59.999em) {
	/* line 33, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
	.unit-converter__list {
		padding-left: 0;
		display: none;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .unit-converter__list {
		padding-left: initial;
		padding-right: 0;
	}
}

/* line 41, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
.unit-converter__list.is-open {
	z-index: 800;
	text-align: left;
	left: 0;
	display: block;
	background-color: rgb(var(--color-global-positive));
	border: 1px solid rgb(var(--color-global-border));
	position: absolute;
	top: 100%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .unit-converter__list.is-open {
	text-align: right;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .unit-converter__list.is-open {
	left: auto;
	right: 0;
}

/* line 51, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
.unit-converter__list.is-open .unit-converter__text {
	display: none;
}

/* line 57, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
.unit-converter__text {
	margin-right: 5px;
	color: rgb(var(--color-global-secondary));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .unit-converter__text {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .unit-converter__text {
		margin-right: 0;
	}
}

/* line 62, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
.unit-converter__mobile-text {
	display: none;
}

@media only screen and (max-width: 59.999em) {
	/* line 62, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
	.unit-converter__mobile-text {
		display: inline-block;
	}
}

/* line 69, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
.unit-converter__toggle {
	left: 0;
	position: absolute;
	top: 0;
	display: none;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .unit-converter__toggle {
	left: auto;
	right: 0;
}

/* line 76, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
.unit-converter__toggle:checked + .unit-converter__handle {
	color: rgb(var(--color-global-positive));
	background-color: rgb(var(--color-global-secondary));
}

/* line 83, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
.unit-converter__handle {
	padding: 2px 5px;
	cursor: pointer;
	color: rgb(var(--color-global-secondary));
}

/* line 89, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
.unit-converter__menu {
	display: none;
}

@media only screen and (max-width: 59.999em) {
	/* line 89, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
	.unit-converter__menu {
		border-radius: 100%;
		display: block;
		width: 30px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		padding: 0;
		border: 1px solid rgb(var(--color-global-border));
		overflow: hidden;
		color: rgb(var(--color-global-secondary));
		box-sizing: border-box;
	}
	/* line 105, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
	.unit-converter__menu:hover {
		color: rgb(var(--color-global-positive));
		background-color: rgb(var(--color-global-secondary));
		transition: background-color .25s linear;
	}
}

/* line 113, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
.unit-converter__menu .unit-converter__menutext {
	margin-left: 30px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .unit-converter__menu .unit-converter__menutext {
	margin-left: initial;
	margin-right: 30px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .unit-converter__menu .unit-converter__menutext {
		margin-left: 0;
	}
}

@media only screen and (max-width: 59.999em) {
	/* line 118, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
	.unit-converter__item {
		display: block;
		width: 100%;
	}
	/* line 124, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
	.unit-converter__item .unit-converter__handle {
		display: block;
		white-space: nowrap;
		padding: 5px 10px;
	}
	/* line 130, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
	.unit-converter__item:hover {
		background-color: rgb(var(--color-global-secondary));
	}
	/* line 133, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
	.unit-converter__item:hover .unit-converter__handle {
		color: rgb(var(--color-global-positive));
	}
}

/* line 139, scss/80-themes/AffinityDefault/70-modules/unitconverter/module.scss */
.unit-converter__item--pill {
	color: rgb(var(--color-global-secondary));
}

/*# sourceMappingURL=../../../../../true */